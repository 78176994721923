import { Trans, useTranslation } from "react-i18next";

const TextViewer = (params) => {
  const { t } = useTranslation();
  const { obszar } = params;
  const { title, content } = t(obszar);
  return (
    <article className="max-w-[530px]">
      <h2 className="pb-12">{title}</h2>
      <div className="h-paragraph overflow-auto pr-5 [&>p]:mb-6 [&>ul]:mb-6 [&>ul]:ml-4 marker">
        <Trans
          i18nKey={content}
          components={{
            p: <p></p>,
            br: <br />,
            strong: <strong></strong>,
            ul: <ul></ul>,
            li: <li className="list-disc"></li>,
          }}
        />
      </div>
    </article>
  );
};

export default TextViewer;
