import { useTranslation } from "react-i18next";
import ScrollableTable from "../../components/ScrollableTable";
import wiatrak from "../../img/wiatrak.jpg";

const Parks = () => {
  const { t } = useTranslation();
  const { title } = t("parks");
  return (
    <div>
      <div className="pb-[15px]">
        <span className="text-2xl">{title}</span>
      </div>
      <ScrollableTable locations={locations} src={wiatrak} />
    </div>
  );
};

export default Parks;

const locations = [
  {
    key: "Dąbrowice 13x3 MW, woj. łódzkie",
    location: {
      lat: 52.31585,
      lng: 19.053642,
    },
  },
  {
    key: "Czarnożyły 11x2 MW, woj. łódzkie",
    location: {
      lat: 51.266284,
      lng: 18.61246,
    },
  },
  {
    key: "Krzyżanów 10x2 MW, woj. łódzkie",
    location: {
      lat: 52.195329,
      lng: 19.442551,
    },
  },
  {
    key: "Wróblew 8x2 MW, woj. łódzkie",
    location: {
      lat: 51.577528,
      lng: 18.624284,
    },
  },
  {
    key: "Gorzkowice 6x2 MW, woj. łódzkie",
    location: {
      lat: 51.216511,
      lng: 19.640908,
    },
  },
  {
    key: "Jędrychwo 4x3 MW, woj. warmińsko-mazurskie",
    location: {
      lat: 53.60775935983161,
      lng: 19.361858367919922,
    },
  },
  {
    key: "Radzanowo 5x2 MW, woj. mazowieckie",
    location: {
      lat: 52.605122,
      lng: 19.935984,
    },
  },
  {
    key: "Lubawa 5x2, woj. warmińsko – mazurskie",
    location: {
      lat: 53.559024,
      lng: 19.757408,
    },
  },
  {
    key: "Wilków 5x2 MW, woj. opolskie",
    location: {
      lat: 51.11407,
      lng: 17.684767,
    },
  },
  {
    key: "Miksztal 5x2 MW, woj. łódzkie",
    location: {
      lat: 52.28613,
      lng: 19.263374,
    },
  },
  {
    key: "Nasielsk, 5x2 MW, woj. mazowieckie",
    location: {
      lat: 52.545617,
      lng: 20.717083,
    },
  },
  {
    key: "Nidzica/Tatary 5x2 MW, woj. warmińsko – mazurskie",
    location: {
      lat: 53.333584,
      lng: 20.426041,
    },
  },
  {
    key: "Błaszki 4x2 MW, woj. łódzkie",
    location: {
      lat: 51.66893,
      lng: 18.409352,
    },
  },
  {
    key: "Kluczbork 4x2 MW, woj. opolskie",
    location: {
      lat: 51.017702,
      lng: 18.177377,
    },
  },
  {
    key: "Szadek 4x2 MW, woj. łódzkie",
    location: {
      lat: 51.706537,
      lng: 18.985657,
    },
  },
  {
    key: "Jedlińsk 4x2 MW, woj. mazowieckie",
    location: {
      lat: 51.538488022842614,
      lng: 19.63153839111328,
    },
  },
  {
    key: "Oporów 4x2 MW, woj. łódzkie",
    location: {
      lat: 52.234196,
      lng: 19.469996,
    },
  },
  {
    key: "Wyszyny Kościelne 3x2 MW, woj. mazowieckie",
    location: {
      lat: 53.057003,
      lng: 20.369111,
    },
  },
  {
    key: "Augustowo 3x2 MW, woj. podlaskie",
    location: {
      lat: 52.783545,
      lng: 23.138198,
    },
  },
  {
    key: "Kargoszyn 3x2 MW, woj. mazowieckie",
    location: {
      lat: 52.910014,
      lng: 20.590373,
    },
  },
  {
    key: "Sobolew 2x3 MW, woj. mazowieckie",
    location: {
      lat: 51.717622,
      lng: 21.703874,
    },
  },
  {
    key: "Lwówek-Krubin 3x2 MW, woj. mazowieckie",
    location: {
      lat: 51.538488022842614,
      lng: 19.63153839111328,
    },
  },
  {
    key: "Szkarada – Sanniki 3x2 MW, woj. mazowieckie",
    location: {
      lat: 52.317253,
      lng: 19.879053,
    },
  },
  {
    key: "Działoszyn 3x2 MW, woj. łódzkie",
    location: {
      lat: 51.142361,
      lng: 18.883304,
    },
  },
  {
    key: "Kuniów 2x2 MW, woj. opolskie",
    location: {
      lat: 50.949015,
      lng: 18.238947,
    },
  },
  {
    key: "Wistka 2x2 MW, woj. łódzkie",
    location: {
      lat: 51.148035,
      lng: 19.110099,
    },
  },
  {
    key: "Wolbórz 2x2 MW, woj. łódzkie",
    location: {
      lat: 51.479384,
      lng: 19.829336,
    },
  },
  {
    key: "Dębina, Głogowa 2x2 MW, woj. wielkopolskie",
    location: {
      lat: 52.269824,
      lng: 18.9198,
    },
  },
  {
    key: "Dziepółć 1x2 MW + 1x0,8 MW, woj. łódzkie",
    location: {
      lat: 51.538488022842614,
      lng: 19.63153839111328,
    },
  },
  {
    key: "Żałe 4x0,8 MW, woj. kujawsko pomorskie",
    location: {
      lat: 53.03403,
      lng: 19.332164,
    },
  },
  {
    key: "Kruszynek-Brzeźno 4x0,8MW, woj. kujawsko pomorskie",
    location: {
      lat: 52.821287,
      lng: 18.759727,
    },
  },
  {
    key: "Sumin 3x0,8 MW, woj. kujawsko – pomorskie",
    location: {
      lat: 53.110286,
      lng: 19.410617,
    },
  },
  {
    key: "Okrajszów 1 x 2 MW, woj. łódzkie",
    location: {
      lat: 51.10597,
      lng: 19.485747,
    },
  },
  {
    key: "Złoczew 1x2 MW, woj. łódzkie",
    location: {
      lat: 51.403735,
      lng: 18.623339,
    },
  },
  {
    key: "Olewin 1x2 MW, woj. łódzkie",
    location: {
      lat: 51.538488022842614,
      lng: 19.63153839111328,
    },
  },
  {
    key: "Gumowo 1x2 MW, woj. mazowieckie",
    location: {
      lat: 52.847107,
      lng: 20.503909,
    },
  },
  {
    key: "Bujnów 1x2 MW, woj. łódzkie",
    location: {
      lat: 51.417158,
      lng: 18.562271,
    },
  },
  {
    key: "Zleszyn 1x2 MW, woj. łódzkie",
    location: {
      lat: 52.183618,
      lng: 19.609817,
    },
  },
  {
    key: "Teresin 1x2MW, woj. łódzkie",
    location: {
      lat: 52.258393,
      lng: 19.127453999999943,
    },
  },
  {
    key: "Jankowice 1x2MW, woj. łódzkie",
    location: {
      lat: 52.219642,
      lng: 19.140038,
    },
  },
  {
    key: "Wołodrza (Perna) 1x2MW, woj. łódzkie",
    location: {
      lat: 51.538488022842614,
      lng: 19.63153839111328,
    },
  },
  {
    key: "Grędzice 1x2 MW, woj. mazowieckie",
    location: {
      lat: 52.860518,
      lng: 20.682508,
    },
  },
  {
    key: "Rypałki Prywatne 1x2 MW, woj. kujawsko – pomorskie",
    location: {
      lat: 53.109854,
      lng: 19.434237,
    },
  },
  {
    key: "Starorypin Prywatny 1x2 MW, woj. kujawsko –\n                              pomorskie",
    location: {
      lat: 53.082703,
      lng: 19.441324,
    },
  },
  {
    key: "Bachorza 1x2 MW, woj. mazowieckie",
    location: {
      lat: 52.386609,
      lng: 22.306649,
    },
  },
  {
    key: "Brzozów 1x2 MW, woj. mazowieckie",
    location: {
      lat: 52.401532,
      lng: 22.16263600000002,
    },
  },
  {
    key: "Nieciecz Włościańska 1x2 MW, woj. mazowieckie",
    location: {
      lat: 52.457635,
      lng: 22.287412,
    },
  },
  {
    key: "Michowo 1x2 MW, woj. mazowieckie",
    location: {
      lat: 52.638343,
      lng: 20.408576,
    },
  },
  {
    key: "Brzeźno 1x2 MW, woj. mazowieckie",
    location: {
      lat: 52.908789,
      lng: 21.625119,
    },
  },
  {
    key: "Goworówek 1x2 MW, woj. mazowieckie",
    location: {
      lat: 52.911377,
      lng: 21.559566,
    },
  },
  {
    key: "Masłowice 1x2 MW, woj. łódzkie",
    location: {
      lat: 51.108346,
      lng: 19.807188,
    },
  },
  {
    key: "Adamów 1x2 MW, woj. łódzkie",
    location: {
      lat: 51.404948,
      lng: 19.322077,
    },
  },
  {
    key: "Witkowo 1x2 MW, woj. mazowieckie",
    location: {
      lat: 51.538488022842614,
      lng: 19.63153839111328,
    },
  },
  {
    key: "Pólka Raciąż 1x2 MW, woj. mazowieckie",
    location: {
      lat: 52.786893,
      lng: 20.088718,
    },
  },
  {
    key: "Antonin 1x2 MW, woj. podlaskie",
    location: {
      lat: 52.681744,
      lng: 22.549699,
    },
  },
  {
    key: "Jarnuty 1x2 MW, woj. podlaskie",
    location: {
      lat: 53.159767,
      lng: 22.012031,
    },
  },
  {
    key: "Złochowice 1x2 MW, woj. śląskie",
    location: {
      lat: 50.927637,
      lng: 18.838177,
    },
  },
  {
    key: "Średnica Jakubowięta 1x2 MW, woj. podlaskie",
    location: {
      lat: 52.885117,
      lng: 22.549557,
    },
  },
  {
    key: "Borówno 2x0,8 MW, woj. kujawsko pomorskie",
    location: {
      lat: 53.134829,
      lng: 18.826686,
    },
  },
  {
    key: "Golub Dobrzyń 2x0,8 MW, woj. kujawsko pomorskie",
    location: {
      lat: 53.115837,
      lng: 19.026428,
    },
  },
  {
    key: "Solec kujawski 2x0,8 MW, woj. kujawsko pomorskie",
    location: {
      lat: 53.083411,
      lng: 18.264253,
    },
  },
  {
    key: "Poczernin 2x0,8 MW, woj. mazowieckie",
    location: {
      lat: 52.559091,
      lng: 20.450222,
    },
  },
  {
    key: "Zakrzewo 2x0,8 MW, wielkopolskie",
    location: {
      lat: 52.371979,
      lng: 18.658237,
    },
  },
  {
    key: "Targowisko Lubawa 2x0,8 MW, woj. warmińsko\n                              mazurskie",
    location: {
      lat: 53.507289,
      lng: 19.699943,
    },
  },
  {
    key: "Siemkowice 2x0,8 MW, woj. łódzkie",
    location: {
      lat: 51.211722,
      lng: 18.920792,
    },
  },
  {
    key: "Marysin 2x0,6 MW, woj. wielkopolskie",
    location: {
      lat: 52.478826,
      lng: 17.691408,
    },
  },
  {
    key: "Kaczanowo/ Września 2x0,6 MW, woj. wielkopolskie",
    location: {
      lat: 51.538488022842614,
      lng: 19.63153839111328,
    },
  },
  {
    key: "Drochowo 2x0,6 MW, woj. mazowieckie",
    location: {
      lat: 52.542238,
      lng: 20.395896,
    },
  },
  {
    key: "Giecz 2x0,6 MW, woj. wielkopolskie",
    location: {
      lat: 52.319343,
      lng: 17.386836,
    },
  },
  {
    key: "Machcino 1x0,85 MW, woj. mazowieckie",
    location: {
      lat: 52.626847,
      lng: 19.758451,
    },
  },
  {
    key: "Kaszewy Dworne 1x0,8 MW, woj. łódzkie",
    location: {
      lat: 52.225335,
      lng: 19.47789,
    },
  },
  {
    key: "Plecka Dąbrowa 1x0,8 MW, woj. łódzkie",
    location: {
      lat: 52.193857,
      lng: 19.647568,
    },
  },
  {
    key: "Nowe Gierałty, 1x0,8 MW, woj. podlaskie",
    location: {
      lat: 52.847077,
      lng: 22.563779,
    },
  },
  {
    key: "Dmochy Rodzonki 1x0,8 MW, woj. podlaskie",
    location: {
      lat: 52.826081,
      lng: 22.347218,
    },
  },
  {
    key: "Pluskowęsy 1x0,8 MW, woj. kujawsko – pomorskie",
    location: {
      lat: 53.181654,
      lng: 18.943778,
    },
  },
  {
    key: "Niedźwiedź 1x0,8 MW, woj. kujawsko pomorskie",
    location: {
      lat: 53.246199,
      lng: 19.026081,
    },
  },
  {
    key: "Golska Huta 1x0,8 MW, woj. kujawsko pomorskie",
    location: {
      lat: 51.538488022842614,
      lng: 19.63153839111328,
    },
  },
  {
    key: "Kamlarki 1x0,8 MW, woj. kujawsko pomorskie",
    location: {
      lat: 53.305861,
      lng: 18.579443,
    },
  },
  {
    key: "Łopatki 1x0,8 MW, woj. kujawsko pomorskie",
    location: {
      lat: 53.324061,
      lng: 19.017004,
    },
  },
  {
    key: "Redecz 1x0,8 MW, woj. kujawsko pomorskie",
    location: {
      lat: 52.544931,
      lng: 18.788081,
    },
  },
  {
    key: "Modliszewko 1x0,8 MW, woj. wielkopolskie",
    location: {
      lat: 52.619189,
      lng: 17.590543,
    },
  },
  {
    key: "Kalinki 1x0,6 MW, woj. łódzkie",
    location: {
      lat: 51.126395,
      lng: 19.835426,
    },
  },
  {
    key: "Smotryszów 1x0,6 MW, woj. łódzkie",
    location: {
      lat: 51.064021,
      lng: 19.602867,
    },
  },
  {
    key: "Gorzewo 1x0,6 MW, woj. wielkopolskie",
    location: {
      lat: 52.795405,
      lng: 16.820483,
    },
  },
  {
    key: "Kaziopole 1x0,6 MW, woj. wielkopolskie",
    location: {
      lat: 51.538488022842614,
      lng: 19.63153839111328,
    },
  },
  {
    key: "Potrzymowo 1x0,6 MW, woj. wielkopolskie",
    location: {
      lat: 52.448121,
      lng: 17.62181,
    },
  },
  {
    key: "Dmenin,1x0,6 MW, woj. łódzkie",
    location: {
      lat: 51.063124,
      lng: 19.567495,
    },
  },
];
