import { Navigate, Outlet } from "react-router-dom";

const Realizations = () => {
  return (
    <>
      <Navigate to="/realizacje/wiatraki" />
      <Outlet />
    </>
  );
};

export default Realizations;
