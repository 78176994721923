const ScrollableTable = ({ locations, src }) => {
  console.log(locations);
  return (
    <div className="w-fit h-[60vh] overflow-auto pr-5">
      <table>
        <tbody>
          {locations.map((loc) => (
            <tr>
              <div className="bg-transparent-black my-[5px] flex">
                <td className="p-[5px]">
                  <img
                    className="w-[120px] h-[140px]"
                    src={src}
                    alt={loc.key}
                  />
                </td>
                <td className="p-[20px] flex items-center">
                  <span className="text-wrap text-center w-[140px] ">
                    {loc.key}
                  </span>
                </td>
              </div>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ScrollableTable;
