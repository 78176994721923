import { Outlet, useLocation } from "react-router-dom";
import Menu from "../components/Menu";
import Navbar from "../components/Navbar";
import { useEffect, useState } from "react";
import LanguageSelector from "../components/LanguageSelector";

const RootLayout = () => {
  const [isActive, setIsActive] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/kontakt") {
      const interval = setInterval(() => {
        setIsActive((prevIsActive) => !prevIsActive);
      }, 6000); // 6000 milliseconds = 6 seconds

      // Cleanup interval on component unmount
      return () => clearInterval(interval);
    }
  }, [location.pathname]);

  // Determine the background class based on the current path
  const backgroundClass =
    location.pathname === "/kontakt"
      ? "bg-siedziba"
      : "bg-wiatrak bg-cover bg-center";

  return (
    <>
      {location.pathname !== "/kontakt" && (
        <div
          className={`${
            isActive ? "opacity-100" : "opacity-0"
          } z-20 absolute inset-0 min-h-screen bg-papier bg-cover bg-center transition-opacity ease-in-out duration-500`}
        ></div>
      )}
      <div
        className={`${
          location.pathname === "/kontakt" ? "" : "opacity-100"
        } z-10 absolute inset-0 min-h-screen ${backgroundClass}`}
      ></div>
      <div className="absolute inset-0 z-30 font-serif min-h-screen text-white">
        <Navbar />
        <div className="flex pt-10">
          <Menu />
          <main className="pl-16 w-full">
            <Outlet />
          </main>
        </div>
        <footer className="w-56 px-12 absolute inset-x-0 bottom-0 pb-5">
          <LanguageSelector />
          <div className="mt-2">
            <span className="text-xs">Copyright@2024</span>
            <br />
            <span className="text-xs">
              Wykonanie:
              <br /> Tymoteusz Tomczak
            </span>
          </div>
        </footer>
      </div>
    </>
  );
};

export default RootLayout;
