import { useTranslation, Trans } from "react-i18next";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Home = () => {
  const { t } = useTranslation();
  const [currentText, setCurrentText] = useState("text1");
  // const currentText = "text1";
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentText((prevText) => {
        const nextText = `text${
          (parseInt(prevText.replace("text", "")) % 4) + 1
        }`;
        return nextText;
      });
    }, 5000);

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  const { title, content, linkto, more } = t(`home.${currentText}`);

  return (
    <article className="w-[710px] text-center">
      <h2 className="pb-12">{title}</h2>
      <div className="text-wrap pr-5 [&>p]:mb-6">
        <Trans
          i18nKey={content}
          components={{
            h3: <h3 className="text-xl"> </h3>,
          }}
        />
        {linkto.toString().length > 0 ? (
          <div className="mt-10">
            <Link className="hover:underline" to={linkto}>
              {more}
            </Link>
          </div>
        ) : (
          ""
        )}
      </div>
    </article>
  );
};

export default Home;
