import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
const Menu = () => {
  const { t } = useTranslation();
  const {
    about,
    offer,
    investor,
    land,
    cooperation,
    projects,
    contact,
    message,
    scope,
    us,
    turbines,
  } = t("menu");
  const location = useLocation();
  const isOfertaActive = location.pathname === "/oferta";
  const active = "hover:text-ahover [&>li]:bg-primary";
  const notActive = "hover:text-primary";
  const childNotActive = "text-white hover:text-ahover";
  const childActive = "hover:text-ahover [&>li]:bg-accent";
  return (
    <menu className="flex-shrink-0 w-56 text-lg leading-snug">
      <ul>
        <NavLink
          to="/about"
          className={({ isActive }) =>
            isActive ? "[&>li]:bg-primary" : notActive
          }
        >
          {({ isActive }) =>
            isActive ? (
              <li>
                <NavLink to="/about/przeslanie" className={childNotActive}>
                  <li className="px-12 py-2">{about}</li>
                </NavLink>
                <NavLink
                  to="/about/przeslanie"
                  className={({ isActive }) =>
                    isActive ? childActive : childNotActive
                  }
                >
                  <li className="px-12 py-2">{message}</li>
                </NavLink>
                <NavLink
                  to="/about/zakres"
                  className={({ isActive }) =>
                    isActive ? childActive : childNotActive
                  }
                >
                  <li className="px-12 py-2">{scope}</li>
                </NavLink>
                <NavLink
                  to="/about/us"
                  className={({ isActive }) =>
                    isActive ? childActive : childNotActive
                  }
                >
                  <li className="px-12 py-2">{us}</li>
                </NavLink>
              </li>
            ) : (
              <li className="px-12 py-2">{about}</li>
            )
          }
        </NavLink>
        <NavLink
          to="/oferta"
          className={({ isActive }) =>
            isActive ? "[&>li]:bg-primary" : notActive
          }
        >
          {({ isActive }) =>
            isActive ? (
              <li>
                <NavLink
                  to="/oferta"
                  className={isOfertaActive ? childActive : childNotActive}
                >
                  <li className="px-12 py-2">{offer}</li>
                </NavLink>
                <NavLink
                  to="/oferta/inwestor"
                  className={({ isActive }) =>
                    isActive ? childActive : childNotActive
                  }
                >
                  <li className="px-12 py-2">{investor}</li>
                </NavLink>
                <NavLink
                  to="/oferta/grunty"
                  className={({ isActive }) =>
                    isActive ? childActive : childNotActive
                  }
                >
                  <li className="px-12 py-2">{land}</li>
                </NavLink>
                <NavLink
                  to="/oferta/wspolpraca"
                  className={({ isActive }) =>
                    isActive ? childActive : childNotActive
                  }
                >
                  <li className="px-12 py-2">{cooperation}</li>
                </NavLink>
              </li>
            ) : (
              <li className="px-12 py-2">{offer}</li>
            )
          }
        </NavLink>
        <NavLink
          to="/realizacje"
          className={({ isActive }) => (isActive ? active : notActive)}
        >
          {({ isActive }) =>
            isActive ? (
              <li>
                <NavLink to="/realizacje/wiatraki" className={childNotActive}>
                  <li className="px-12 py-2">{projects}</li>
                </NavLink>
                <NavLink
                  to="/realizacje/wiatraki"
                  className={({ isActive }) =>
                    isActive ? childActive : childNotActive
                  }
                >
                  <li className="px-12 py-2">{turbines}</li>
                </NavLink>
              </li>
            ) : (
              <li className="px-12 py-2">{projects}</li>
            )
          }
        </NavLink>
        <NavLink
          to="/kontakt"
          className={({ isActive }) => (isActive ? active : notActive)}
        >
          <li className="px-12 py-2">{contact}</li>
        </NavLink>
      </ul>
    </menu>
  );
};

export default Menu;
